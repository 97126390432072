<template>
  <div class="notice">
    <div class="notice__wrap">
      <div @click="removePopup" class="notice__close"><v-icon>mdi-close</v-icon></div>
      <div class="notice__title">{{title}}
        <p class="notice__subtitle">{{subtitle}}</p>
      </div>
      <div @click="removePopup" class="notice__btn">
        Ok
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'notification',
  props: {
    title: String,
    subtitle: String,
    isPopup: Boolean
  },
  emits: [
    'removePopup'
  ],
  methods: {
    removePopup () {
      this.$emit('removePopup')
    }
  }
}
</script>

<style lang="scss">
.notice {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(0,0,0, 0.7);
  z-index: 1000;
  .notice__wrap {
    background: white;
    position: relative;
    display: inline-block;
    padding: 20px;
    /*width: 70%;*/
    /*min-height: calc(60% + 100px);*/
    max-width: 500px;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    border-radius: 5px;
    .notice__close {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }
    .notice__title {
      top: calc(10% + 50px);
      text-align: center;
      font-size: calc( (100vw - 320px)/(1920 - 320) * (20 - 5) + 14px);
      color: black;
      margin: 0 10px 30px 10px;
      font-weight: 600;
      p {
        font-size: calc( (100vw - 320px)/(1920 - 320) * (14 - 5) + 12px);;
        font-weight: initial;
      }
    }
    .notice__btn {
      width: 50%;
      margin: 0 auto;
      background-color: #0F8831;
      color: white;
      text-align: center;
      border-radius: 4px;
      padding: 5px;
      cursor: pointer;
    }
  }
}
</style>
